.file input {
  display: none;
}

.file div {
  display: flex;
}
.file > div {
  border-bottom: solid 1px #000;
}

.file div span, .file div label {
  flex: 1;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}
.file div span:hover, .file div label:hover {
  background-color: #fff;
}

.file-history {
  display: flex;
  flex-direction: column;
}

